import { userDetailAxios } from "../index";

export const ApplicationService = {
    updateApplicationContingency,
}

/**
 * Updates application contingency
 * @param {Object} body - The request body
 * @param {string} body.requisitionId - The requisition ID
 * @param {string} body.laborOrderId - The labor order ID
 * @param {string[]} body.applicationIds - An array of application IDs
 * @returns {Promise<any>} - A promise that resolves with the response data
 */
function updateApplicationContingency(body) {
    return userDetailAxios.post(`/application/updateApplicationContingency`, body)
}