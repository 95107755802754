import React, {Fragment, useEffect, useState} from "react";
import {paginate} from "../../Utils/LaborOrderGridOperations";
import CandidateDataTable from "../../components/DataTable/CandidateDataTable";
import {searchCandidate, sortCandidates} from "../../Utils/CandidateGridOperations";
import {getCandidateHiringStage} from "../../Utils/CandidateDataUtils";
import constants, {FEATURE_FLAGS_LOCALSTORAGE_KEYS} from "../../Utils/Constants";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {useDownloadCandidateData} from "../../ReactQuery/hooks/useDownloadCandidateData";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useMutation, useQueryClient} from "react-query";
import { isMenaUser } from "../../Utils/RegionUtil";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Stack} from "@mui/material";
import ButtonSecondary from "../../components/ButtonSecondary/ButtonSecondary";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import { ApplicationService } from "../../Services/application.js";
import FormActionAlertDialog from "../../components/FormPage/FormActionAlertDialog";

const commonColumnHeaders = [        
    { id: 'applicationId', title: 'Application ID', sortable: true, headerStyle: { color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
    { id: 'hiringStage', title: 'Hiring Stage', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
    { id: 'assessment', title: 'Assessment', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
    { id: 'bgvStatus', title: 'BGV Status', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true },
    { id: 'rehireFlag', title: 'Rehire Flag', sortable: true, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, visibility: true }
];
const additionalMenaColumnHeaders =  [
    { id: 'firstName', title: 'First Name', sortable: true, headerStyle:{ color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
    { id: 'lastName', title: 'Last Name', sortable: true, headerStyle: { color: '#99a0aa', textTransform: 'capitalize' }, rowStyle: { color: '#99a0aa', textTransform: 'capitalize' }, visibility: true },
];

function CandidateDataGrid(props) {
    const [isEDMFeatureEnabled] = useLocalStorage(FEATURE_FLAGS_LOCALSTORAGE_KEYS.EDM_INTEGRATION, false);
    const [rowData, setRowData] = React.useState(null);
    const [totalRowsCount, setTotalRowsCount] = React.useState(0);
    const [paginationConfig, setPaginationConfig] = React.useState(props.config);
    const [globalRows, setGlobalRows] = React.useState([]);
    const [requestBody, setRequestBody] = useState(null)

    const [showOnboardingDialog, setShowOnboardingDialog] = useState(false);

    const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
    const actionMenuOpen = Boolean(actionMenuAnchorEl);

    const {data: queryCandidateData, isFetching: candidatesLoading, error: candidatesError} = useDownloadCandidateData(requestBody)
    const queryClient = useQueryClient();

    const {mutateAsync: mutateApplication, isLoading: mutateApplicationIsLoading} = useMutation({
      mutationFn: (body) => ApplicationService.updateApplicationContingency(body)
    })

    const selectedRows = (rowData ?? []).filter((row) => row?.is_selected);


    useEffect(() =>{
        if(props.config.laborOrderId && props.config.requisitionId){
            setRequestBody({laborOrderId:props.config.laborOrderId,requisitionId:props.config.requisitionId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.config.laborOrderId,props.config.requisitionId]);

    const handleSearchClick = (searchValue) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchValue;
        paginationConfigL.searchColumn = searchValue ? 'site' : '';
        setPaginationConfig(paginationConfigL);
    }
    useEffect(() => {
        if (paginationConfig && paginationConfig.pageSize) {
            operateOnRows();
        }
        if(paginationConfig && paginationConfig.laborOrderId && paginationConfig.requisitionId){
            setRequestBody({laborOrderId:paginationConfig.laborOrderId,requisitionId:paginationConfig.requisitionId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationConfig, globalRows])

    useEffect(() => {
        if ((props.config.searchValue && props.config.searchValue!=="") || props.config.searchValue==='') {
            handleSearchClick(props.config.searchValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config.searchValue])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[queryCandidateData, candidatesError])

    useEffect(() => {
        setRowData(null)
    },[requestBody])

    const handleSort = (clickedColumn) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.sortColumn = clickedColumn;
        paginationConfigL.sortOrder = paginationConfigL.sortOrder && paginationConfigL.sortOrder === 'asc' ? 'desc' : 'asc';
        setPaginationConfig(paginationConfigL);
    };

    const handlePage = (page) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = page + 1;
        setPaginationConfig(paginationConfigL);
    };

    const handlePageSize = (pageSize) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.pageSize = pageSize;
        setPaginationConfig(paginationConfigL);
    };

    const operateOnRows = () => {
        let rowsData = [...globalRows];
        rowsData= sortCandidates(rowsData,paginationConfig.sortColumn,paginationConfig.sortOrder)
        rowsData = searchCandidate(rowsData, paginationConfig.searchValue);
        setTotalRowsCount(rowsData.length);
        rowsData = paginate(rowsData ,paginationConfig.pageSize, paginationConfig.pageNo);
        setRowData(rowsData);
    }

    const getData = () => {
        if(candidatesError){
            props.snackbarShowMessage("Error fetching candidates for this requisition", "error", "3000");
            setRowData([]);
            setGlobalRows([]);
            setTotalRowsCount(0);
            queryClient.invalidateQueries([constants.REACT_QUERY.QUERY_KEY.GET_ALL_REQUISITIONS,null]);
        }
        else {
            let rows = queryCandidateData && queryCandidateData.data && queryCandidateData.data.candidates;
            if (!candidatesLoading)
                populateGrid(rows)
        }

    };

    const populateGrid = (rows) => {
        let rowsData = []
        if (rows) {
            rows.forEach(row => {
                rowsData.push({
                    applicationId: row.candidateId,
                    hiringStage: row.hiringStage? getCandidateHiringStage(row.hiringStage): constants.CANDIDATE_MANAGEMENT.HIRING_STAGES.APPLICATION_SUBMITTED,
                    assessment: (row.assessmentScore && row.assessmentResult && row.assessmentScore!=="-" && row.assessmentResult!=="-") ? `${row.assessmentResult} (${row.assessmentScore})` : "-",
                    bgvStatus: row.bgvStatus ? row.bgvStatus : "-",
                    rehireFlag : row.rehireFlag,
                    createdBy : row.createdBy ? row.createdBy : "",
                    onBoardedRequisition : row.onBoardedRequisition ? row.onBoardedRequisition : " - ",
                    firstName: row.firstName,
                    lastName: row.lastName,
                    laborOrderId: row.laborOrderId,
                    requisitionId: row.requisitionId,
                });
            });
        }
        setRowData(rowsData);
        setGlobalRows(rowsData);
        setTotalRowsCount(rowsData.length)
    }

    const getCandidateTableColumnHeaders = () => {
        return isMenaUser() ? [...additionalMenaColumnHeaders,...commonColumnHeaders] : commonColumnHeaders;
    }

    const getRowsCheckedCount = (rowData) => {
        let checkedCount = 0;
        if(rowData)
        rowData.forEach(row => checkedCount = row.is_selected ?  checkedCount + 1: checkedCount);
        return checkedCount;
    }

    const handleSelectAllClick = (event) => {
        let newRowData = [...rowData];
        newRowData.forEach(row => row.is_selected = event.target.checked)
        setRowData(newRowData);
    };

    const getIndex = (rowId, rows, isSelectionNeeded) => {
        let selectedIndex = -1
        for(let index = 0; index < rows.length; index++) {
            if(rows[index].applicationId === rowId) {
                if((isSelectionNeeded && rows[index].is_selected) || !isSelectionNeeded ) {
                    selectedIndex = index;
                    break;
                }
            }
        }
        return selectedIndex
    }

    const rowSelect = (e, row) => {
        const selectedIndex = getIndex(row.applicationId, rowData,false);
        let newRowData = [...rowData];
        newRowData[selectedIndex].is_selected = !newRowData[selectedIndex].is_selected;
        setRowData(newRowData);
    }

    const handleActionMenuOpen = (event) => {
        setActionMenuAnchorEl(event.currentTarget)
    }

    const handleActionMenuClose = () => {
        setActionMenuAnchorEl(null)
    }

    const handleShowOnboardingDialog = () => {
      handleActionMenuClose();
      if(!selectedRows.length) {
        return;
      }
      setShowOnboardingDialog(true);
    }

    const handleMarkOnboardingComplete = async () => {
        const readyToHireCandidates = selectedRows.filter((row => row.hiringStage === "Ready To Hire"));
        const errorMessage = `${selectedRows.length} applications could not be marked.`;

        if(!readyToHireCandidates.length) {
          props.snackbarShowMessage(errorMessage, 'error');
          return;
        }

        const laborOrderId = readyToHireCandidates?.[0].laborOrderId;
        const requisitionId = readyToHireCandidates?.[0].requisitionId;
        const applicationIds = readyToHireCandidates.map((candidate) => candidate.applicationId);

        if(!laborOrderId || !requisitionId || !applicationIds.length) {
          props.snackbarShowMessage(errorMessage, 'error');
          return;
        }

        try {
          await mutateApplication({
            laborOrderId,
            requisitionId,
            applicationIds
          });

          const successMessage = `${applicationIds.length} candidate applications marked "Onboarding complete".`;
          const warningMessage = applicationIds.length !== selectedRows.length ? `${selectedRows.length - applicationIds.length} applications could not be marked.` : null;

          props.snackbarShowMessage([successMessage, warningMessage].filter(Boolean).join(' '), 'success');
        } catch(err) {
          props.snackbarShowMessage(errorMessage, 'error');
        } finally {
          queryClient.invalidateQueries([constants.REACT_QUERY.QUERY_KEY.DOWNLOAD_CANDIDATE_DATA])
        }
    }


    return (
      <div>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Stack direction="column">
            <div style={{ fontSize: "12px" }}>
              <div class="box orange"></div> Auto-Moved: Candidate did not exist
              against this requisition originally
            </div>
            <div style={{ fontSize: "12px" }}>
              <div class="box blue"></div> Candidate 'Onboarding - Complete'
              against different requisition(s)
            </div>
          </Stack>
          {/* Only show Action Menu for MENA countries & EDM Feature Flag enabled */}
          {isEDMFeatureEnabled && isMenaUser() && (
            <Fragment>
              <ButtonSecondary
                size="medium"
                Title="Actions"
                onClick={handleActionMenuOpen}
                endIcon={<KeyboardArrowDownIcon />}
              />
              <Menu
                anchorEl={actionMenuAnchorEl}
                open={actionMenuOpen}
                onClose={handleActionMenuClose}
              >
                <MenuItem onClick={handleShowOnboardingDialog} disabled={mutateApplicationIsLoading}>
                  Mark onboarding complete
                </MenuItem>
              </Menu>
            </Fragment>
          )}
        </Stack>
        <div className="d-flex">
          <div className="amz_datatable activities_grid">
            <CandidateDataTable
              id="amz_CandidateDataTable"
              columnData={getCandidateTableColumnHeaders()}
              rowData={candidatesLoading || !rowData ? [] : rowData}
              pageSize={
                paginationConfig.pageSize ? paginationConfig.pageSize : 25
              }
              handlePageSize={handlePageSize}
              pageNo={paginationConfig.pageNo ? paginationConfig.pageNo : 1}
              handlePage={handlePage}
              handleSort={handleSort}
              sortColumn={
                paginationConfig.sortColumn ? paginationConfig.sortColumn : null
              }
              sortOrder={
                paginationConfig.sortOrder ? paginationConfig.sortOrder : null
              }
              count={totalRowsCount}
              noDataFound={
                candidatesLoading || !rowData ? (
                  <CircularProgress />
                ) : (
                  "No Candidates Found!"
                )
              }
              getIndex={getIndex}
              rowSelect={rowSelect}
              getRowsCheckedCount={getRowsCheckedCount}
              onSelectAllClick={handleSelectAllClick}
            />
          </div>
        </div>
        {
          showOnboardingDialog && (
            <FormActionAlertDialog
            handleYes={() => {
              handleMarkOnboardingComplete();
              setShowOnboardingDialog(false);
            }} 
            handleNo={() => {
              setShowOnboardingDialog(false);
            }} 
            Message={`The ${selectedRows.length} selected candidates will be marked "Onboarding complete". This action cannot be reversed.`}/>
          )
        }
  </div>
    );
}

export default withAlertSnackBar(CandidateDataGrid)